import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Header from '../../Common/Headers/Header';
import { Theme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AlertDialog from '../../Common/Popups/AlertDialog';

import button_theme from '../../Common/Themes/button_theme';
import client from '../../Common/utils/client';
import * as routes from '../unitdetail_apiRoutes';

import '../../Common/stylesheets/global.scss';
import { removePendingWorkTicket } from '../../Common/utils/storageInterface';
import { Styles } from '@material-ui/core/styles/withStyles';

const styles: Styles<Theme, any> = (theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: '24px',
    float: 'right'
  }
});

interface RemoveHubProps {
  classes: any;
  unit: {
    gatewayId: number;
    uid: number;
    unit: string;
    communityName: string;
  };
  match: any;
  history: any;
  user: any;
  callback: () => void;
}

interface RemoveHubState {
  alertdialog: boolean;
  error: string;
  serial_number: string | null;
  loading: boolean;
}

class RemoveHub extends Component<RemoveHubProps, RemoveHubState> {
  classes: any;
  constructor(props) {
    super(props);
    const { classes } = props;
    this.classes = classes;
    this.state = {
      serial_number: null,
      alertdialog: false,
      error: '',
      loading: false
    };
  }

  openAlert = (e) => {
    e.preventDefault();
    this.setState({
      alertdialog: true
    });
  };

  closeDialog = (e) => {
    e.preventDefault();
    this.setState({ alertdialog: false });
  };

  removeHub = (e) => {
    e.preventDefault();
    let data = {
      gateway_id: this.props.unit.gatewayId,
      address_id: this.props.unit.uid
    };
    this.setState({ loading: true });
    client
      .post(routes.REMOVE_HUB, data)
      .then(() => {
        this.setState({ loading: false });
        removePendingWorkTicket();
        window.location.reload();
        return Promise.resolve();
      })
      .catch((error) => {
        this.setState({ loading: false });
        Sentry.captureException(error);
        if (error.response?.status === 401) {
          this.props.history.push('/login');
        } else {
          this.setState({
            error:
              error.response?.data['custom'] ?? 'An unknown error occurred. Please try again.'
          });
        }
      });
  };
  return = () => {
    const communityId = this.props.match.params.communityId;
    this.props.history.push('/community/' + communityId);
  };

  cancel = () => {
    let { callback } = this.props;
    if (callback) {
      callback();
    }
  };

  render() {
    return (
      <div className="full-height">
        <div className="addunit-container">
          <Header
            headerText="Remove Hub"
            back={true}
            return={this.return}
            arrow={true}
            user={this.props.user}
            history={this.props.history}
            subheaderText={this.props.unit.communityName}
            subheaderText2={this.props.unit.unit}
          />
          <div>
            <p
              style={{
                padding: '24px 24px 16px 24px',
                color: 'rgba(0, 0, 0, 0.54)'
              }}
            >
              Are you ready to set up a new hub in this unit?
              <br />
              Make sure you have the following:
            </p>
            <ol style={{ listStyleType: 'decimal', paddingRight: '24px' }}>
              <li>Physical access to the unit.</li>
              <li>Replacement boss hub.</li>
            </ol>
            <p
              style={{
                paddingLeft: '24px',
                paddingRight: '24px',
                color: 'rgba(0, 0, 0, 0.54)'
              }}
            >
              Removing the hub cannot be undone.
            </p>
          </div>
          <MuiThemeProvider theme={button_theme}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={this.openAlert}
              className={this.classes.button}
            >
              Remove hub
            </Button>
            <Button
              variant="text"
              size="medium"
              color="primary"
              onClick={this.cancel}
              className={this.classes.button}
            >
              INCOMPLETE
            </Button>
          </MuiThemeProvider>
        </div>
        <AlertDialog
          alertdialog={this.state.alertdialog}
          onClick={this.removeHub}
          onClose={this.closeDialog}
          error={this.state.error}
          loading={this.state.loading}
          disableClick={true}
          copy={true}
          copyText={this.props.unit.gatewayId}
          text="This will remove the hub and all included devices from the unit. This action cannot be undone. Please copy the old gateway id if you wish to proceed - this will be necessary to transfer permissions later."
          title="Remove hub?"
        />
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(RemoveHub);
