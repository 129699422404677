import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import DeviceDetail from './components/DeviceDetail';

import * as routes from './devicedetail_apiRoutes';
import withSpinner from '../Common/utils/withSpinner';
import client from '../Common/utils/client';
import withAuth from '../Common/utils/withAuth';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import { getCodeSyncStatusByDevice } from '../Common/CodeSyncing/apiService/api';
import { LOCK } from '../Common/Constants/deviceConstants';
import { DOOR_CODE_RELIABILITY } from '../Common/Constants/launchDarklyConstants';
import {
  captureExceptionIfNot424,
  handle401ErrorRedirect,
  handleNon401ErrorRedirect,
  is401Error
} from '../Common/utils/errorHandling';

const DeviceDetailWithSpinner = withSpinner(DeviceDetail);

class DeviceDetailPage extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      device: {},
      devices: [],
      sfConfiguration: {},
      unit: {},
      codeSyncingData: null
    };
  }

  shouldUseCodeSyncingForDevice = (deviceType) => {
    return deviceType === LOCK && this.props.flags[DOOR_CODE_RELIABILITY];
  };

  componentDidMount() {
    const communityId = this.props.match.params.communityId;
    const unitId = this.props.match.params.unitId;
    const deviceId = this.props.match.params.deviceId;
    let devices, device;
    client
      .get(routes.ADDRESS(unitId))
      .then((res) => {
        let data = res.data.results;
        if (
          res.data.results.length === 0 ||
          Number(data[0].communityId) !== Number(communityId)
        ) {
          this.props.history.push('/');
        } else {
          this.setState({
            unit: data[0]
          });
          if (data[0].hub_serial) {
            return Promise.all([
              client.get(routes.ALL_DEVICE_SENSORS(data[0].hub_serial)),
              client
                .get(routes.COMMUNITY_AND_UNIT_CONFIGURATIONS(communityId, unitId))
                .catch((e) => {
                  captureExceptionIfNot424(e);
                })
            ]);
          } else {
            Promise.reject();
          }
        }
      })
      .then((res) => {
        devices = res[0].data?.results;
        device = devices.find((device) => device.uid === Number(deviceId));
        if (!device) {
          this.props.history.goBack();
        }
        const responsesToReturn = [...res];
        if (this.shouldUseCodeSyncingForDevice(device.deviceType)) {
          responsesToReturn.push(
            getCodeSyncStatusByDevice(deviceId).catch((e) => {
              Sentry.captureException(e);
            })
          );
        } else {
          responsesToReturn.push([]);
        }
        return Promise.all(responsesToReturn);
      })
      .then((res) => {
        const newState = {
          devices: devices,
          device: device,
          loading: false,
          sfConfiguration: res[1]?.data
        };
        if (this.shouldUseCodeSyncingForDevice(device.deviceType)) {
          newState['codeSyncingData'] = res[2].data || null;
        }
        this.setState(newState);
      })
      .catch((error) => {
        if (is401Error(error)) {
          handle401ErrorRedirect(error, this.props.history);
        } else {
          handleNon401ErrorRedirect(error, this.props.history);
        }
      });
  }
  render() {
    return (
      <DeviceDetailWithSpinner
        isLoading={this.state.loading}
        devices={this.state.devices}
        device={this.state.device}
        unit={this.state.unit}
        sfConfiguration={this.state.sfConfiguration}
        codeSyncingData={this.state.codeSyncingData}
        {...this.props}
      />
    );
  }
}

export default withLDConsumer()(withAuth(DeviceDetailPage));
